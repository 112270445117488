<template>
    <div class="bg-white rounded-lg p-2 shadow-md mx-4 mt-4">
        <div class="bg-red-300 p-2 mb-4 hidden">
            DEBUG
            <p>{{participante}}</p>
    
            <p>{{evento}}</p>
        </div>
        <div class="flex flex-col justify-between p-2 sm:flex-row">
            <div class="sm:w-1/2 bg-gray-100 p-4 rounded">
                <h2 class="text-lg font-semibold mb-2">Detalles del Evento</h2>
                <p><strong>Título:</strong> {{ evento.titulo }}</p>
                <p><strong>Descripción:</strong> {{ evento.descripcion }}</p>
                <p><strong>Fecha:</strong> {{ evento.fecha }}</p>
                <!-- Agrega más detalles del evento según sea necesario -->
            </div>
            <div class="sm:w-1/2 bg-gray-100 p-4 rounded">
                <h2 class="text-lg font-semibold mb-2">Detalles del Participante</h2>
                <p><strong>Nombre:</strong> {{ participante.nombre }}</p>
                <p><strong>Pago:</strong> {{ participante.pago }}</p>
                <p><strong>Fecha de Pago:</strong> {{ participante.fecha_de_pago }}</p>
                <!-- <p><strong>Estado:</strong> {{ participante.estado }}</p> -->
                <div v-if="participante.estado == 'valido'">
                    <p class=""><strong>Estado</strong></p>
                    <div class="text-sm font-medium leading-5 mt-2 text-gray-900 bg-green-400  rounded-lg "><button class="btn btn-sm btn-success  uppercase">{{participante.estado}}</button></div>
                </div>
                <div v-if="participante.estado == 'canjeado'">
                    <p class=""><strong>Estado</strong></p>
                    <div class="text-sm font-medium leading-5 mt-2 text-gray-900 bg-red-400  rounded-lg "><button class="btn btn-sm  uppercase">{{participante.estado}}</button></div>
                </div>
                <!-- <div v-if="participante.estado == 'canjeado'" class="text-sm font-medium leading-5 text-gray-900 bg-red-400  rounded-lg "><button class="btn btn-sm btn-success  uppercase">{{participante.estado}}</button></div> -->
                <!-- Agrega más detalles del participante según sea necesario -->
            </div>
        </div>
    
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    layout: "entrada",
    data() {
        return {
            participante: null,
            evento: null,
        };
    },
    computed: {
        event() {
            return this.$store.state.events.selectedEvent; // Asume que tienes un state "selectedEvent" en tu módulo Vuex "events"
        },
        eventId() {
            return this.$route.params.id;
        },
    },
    async created() {
        await this.fetchParticipante();
    },
    methods: {
        ...mapActions("participantes", {
            findParticipantes: "find"
        }),
        ...mapActions("events", {
            findEventos: "find"
        }),
        formatDate(date) {
            return new Date(date).toLocaleString();
        },
        async fetchParticipante() {
            try {
                const response = await this.findParticipantes({ query: { _id: this.eventId, $limit: 1 } });
                const participantes = response.data;
                const participante = participantes[0];
                this.participante = participante;
                await this.fetchEvent(this.participante.evento_id);

            } catch (error) {
                console.error("Error fetching event:", error);
            }
        },
        async fetchEvent(id) {
            try {
                const response = await this.findEventos({ query: { _id: id, $limit: 1 } });
                const eventData = response.data;
                const evento = eventData[0];
                this.evento = evento;

                // Realiza cualquier lógica adicional con los datos del evento aquí

            } catch (error) {
                console.error("Error fetching event:", error);
            }
        }
    }
};
</script>